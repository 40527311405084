<template>
  <div class="stack">
    <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
    <page-block v-else-if="!isRequest" style="margin-top: 0">
      <div class="stack-info__content">
        <page-title class="stack-info__title">
          {{ $t('title.server') }}
        </page-title>
        <div :class="{ visible: true }" class="stack-info__create">
          <!--          <base-button-->
          <!--            :disabled="isFirewalls === 0"-->
          <!--            class="stack-info__create-btn"-->
          <!--            @click="newFirewall()"-->
          <!--          >-->
          <!--            {{ $t('newFirewall') }}-->
          <!--          </base-button>-->
          <base-button
            :tooltip="{
              content: text,
              autoHide: false,
              placement: 'auto',
              container: false,
              trigger: 'click hover',
            }"
            :icon="isIcon"
            :color="isColor"
            :disabled="isRequest"
            class="stack-info__create-btn"
            @click="isFirewalls !== 0 ? newFirewall() : ''"
            >{{ $t('newFirewall') }}
          </base-button>
        </div>
      </div>
      <base-empty v-if="list.length === 0" title="Новый файрвол" class="cloud-info__empty">
        <template #link
          ><base-button>
            {{ $t('newFirewall') }}
          </base-button></template
        >
      </base-empty>
      <tariffs-table-firewall />
    </page-block>
  </div>
</template>

<script>
import TariffsTableFirewall from '../../Main/components/TariffsTableFirewall';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import storeMixin from '../../../mixins/index';
import newFirewall from '@/mixins/newFirewall';
export default {
  name: 'ViewFirewall',
  components: {
    TariffsTableFirewall,
    BaseEmpty,
  },
  mixins: [storeMixin, newFirewall],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isRequest: true,
      diskId: '',
    };
  },
  computed: {
    isIcon() {
      return this.isFirewalls === 0 ? 'error' : '';
    },
    isColor() {
      return this.isFirewalls === 0 ? 'warn' : 'primary';
    },
    text() {
      if (this.isFirewalls === 0) {
        return 'Вы достигли квоты по количеству файрволов, <br />обратитесь в поддержку для изменения квоты';
      } else {
        return 'Создать новый файрвол';
      }
    },
    isFirewalls() {
      if (
        this.$store.state.moduleStack.quotasNetwork &&
        this.$store.state.moduleStack.quotasNetwork.security_group
      ) {
        return (
          this.$store.state.moduleStack.quotasNetwork.security_group.limit -
          this.$store.state.moduleStack.quotasNetwork.security_group.used
        );
      } else return 0;
    },
  },
  mounted() {
    this.getOpenStackApiKey()
      .then(() => {
        this.newFetch();
      })
      .catch(error => {
        this.showError(error);
        setTimeout(() => this.$router.push({ name: 'Login' }), 400);
      });
    // this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'volumes').then(data => {
    //   let volumeId = data.volumes
    //     .filter(x => x.status === 'creating' || x.status === 'queued')
    //     .map(x => x.id);
    //   if (volumeId.length > 0) {
    //     volumeId.forEach(x => {
    //       console.log(x);
    //       this.diskId = x;
    //       this.updateDiskStatus(_, x);
    //     });
    //   }
    // });
  },
  methods: {
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
    validateOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.id);
    },
    // async updateDiskStatus(callback, payload) {
    //   this.timerId = setTimeout(() => {
    //     this.$store
    //       .dispatch('moduleStack/checkDiskStatus', {
    //         disk: this.diskId,
    //         type: 'volumes',
    //         silent: true,
    //       })
    //       .then(async data => {
    //         if (['extending'].includes(data.status)) {
    //           this.res = data.status;
    //           await this.updateDiskStatus(callback, payload);
    //         } else {
    //           // console.log(data);
    //           this.timerCnt = 0;
    //           this.isProcessing = false;
    //           clearTimeout(this.timerId);
    //           this.res = data.status;
    //           return 'success';
    //         }
    //       });
    //   }, 1000 * this.timerCnt);
    // },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "server": "Файрвол"
    },
    "sure": {
      "confirm": "Создать"
    },
    "success": "Файрвол успешно создан",
    "quotaFirewall": "Вы достигли квоты по количеству файрволов, обратитесь в поддержку для изменения квоты",
    "newFirewall": "Новый файрвол"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-header {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
      //flex-direction: column;
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-top: 1.75rem;
        margin-bottom: 1.5rem;
        flex: 1 1 auto;
      }
    }
  }

    &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
      //flex-direction: column;
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-bottom: 1.5 rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
        display: flex;
        flex-direction row;
        align-content flex-end;
        align-items baseline;
        margin-top: 1.5rem;
        max-width: 200px;
        &-btn {
          margin-top: 20px
          margin-right: 0;
          +breakpoint(ms-and-up) {
            margin-top: 20px;
          }
        }
        &-hint {
          margin: -0.25rem 0.25rem;
          vertical-align: middle;
        }

        +breakpoint(sm-and-up) {
          margin: 0 0 0 auto;
        }
      }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
